import React, { useEffect } from 'react';
import { Page } from 'src/app/components';
import logo from 'src/assets/images/equilibre_logo.png';

const ProspectFaq = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#f2f7f9';
  });

  return (
    <Page title="Faq">
      <Page.Main className="container flex flex-col max-w-screen-lg py-20 equilibre-faq">
        <span className="equilibre-faq-title">Vos questions / Nos réponses</span>

        <div className="equilibre-faq-entry">
          <h2 className="question">Quel est l’objectif du Programme EQUILIBRE ?</h2>

          <div className="answer">
            <ul>
              <li>
                Pour votre entreprise, le Programme EQUILIBRE permet d’accroître{' '}
                <span className="equilibre-bold">
                  votre engagement comme acteur de prévention continue
                </span>
                , de participer activement au{' '}
                <span className="equilibre-bold">déploiement de votre politique RSE</span> et de
                contribuer directement à{' '}
                <span className="equilibre-bold">améliorer la qualité de vie au travail</span> de
                tous vos collaborateurs.
              </li>
              <li>
                Pour vos collaborateurs, le Programme EQUILIBRE engage et motive chacun d’entre eux
                à{' '}
                <span className="equilibre-bold">
                  passer à l’action en adoptant progressivement les bons comportements
                </span>{' '}
                pour leur capital santé et bien-être.
              </li>
            </ul>
          </div>
        </div>
        <div className="equilibre-faq-entry">
          <h2 className="question">
            Le Programme EQUILIBRE peut-il être proposé à tous les collaborateurs de mon entreprise
            ?
          </h2>
          <div className="answer">
            <ul>
              <li>
                Oui tout à fait ! Le Programme EQUILIBRE a été conçu spécifiquement pour s’adresser
                à{' '}
                <span className="equilibre-bold">
                  l’ensemble de vos collaborateurs, salariés et prestataires.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="equilibre-faq-entry">
          <h2 className="question">
            Le Programme EQUILBRE est-il juste un accès à un site internet d'information grand
            public ?
          </h2>
          <div className="answer">
            <ul>
              <li>
                Non, c'est un programme de prévention complet qui s’appuie sur un dispositif de promotion et d’animation à déployer en ligne et sur le lieu de travail.
              </li>
              <li>
                Le Programme EQUILIBRE est composé de{' '}
                <span className="equilibre-bold">
                  la solution digitale et de ses contenus (articles, challenges, quiz, vidéos,
                  questionnaire de satisfaction) réalisés et validés par notre comité scientifique,
                  d’un kit clé en main pour promouvoir le service au sein de votre entreprise et
                  entretenir son usage dans la durée, et d'outils de monitoring.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="equilibre-faq-entry">
          <h2 className="question">
            Combien de parcours sont disponibles dans le Programme EQUILIBRE ?
          </h2>
          <div className="answer">
            <ul>
              <li>
                <span className="equilibre-bold">4 thématiques au choix</span> sont proposées à
                chacun des utilisateurs :{' '}
                <span className="equilibre-bold">
                  Alimentation, Gestion du Stress, Sommeil, Activité Physique.
                </span>
              </li>
              <li>
                Le Programme EQUILIBRE peut sur demande intégrer un parcours sur mesure d’une
                thématique identifiée par votre entreprise.
              </li>
            </ul>
          </div>
        </div>
        <div className="equilibre-faq-entry">
          <h2 className="question">
            Combien de temps est nécessaire pour faire le Programme EQUILIBRE ?
          </h2>
          <div className="answer">
            <ul>
              <li>
                Chaque participant n’a besoin que de{' '}
                <span className="equilibre-bold">
                  5 minutes par jour, 2 fois par semaine pendant 8 semaines
                </span>{' '}
                pour débuter, progresser et finaliser l’un des 4 parcours proposés.
              </li>
            </ul>
          </div>
        </div>
        <div className="equilibre-faq-entry">
          <h2 className="question">Qui a créé le Programme EQUILIBRE ?</h2>
          <div className="answer">
            <ul>
              <li>
                <span className="equilibre-bold">Sharecare</span> et le{' '}
                <span className="equilibre-bold">Centre Prévention Santé Longévité</span> de
                l’Institut Pasteur de Lille ont créé un partenariat spécifique pour le Programme
                EQUILIBRE.
              </li>
              <li>
                Pour en savoir plus sur Sharecare :{' '}
                <a href="https://www.sharecare.fr" target="_blank" rel="noreferrer">
                  https://www.sharecare.fr
                </a>
              </li>
              <li>
                Pour en savoir plus sur le CPSL :{' '}
                <a
                  href="https://pasteur-lille.fr/centre-prevention-sante-longevite/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://pasteur-lille.fr/centre-prevention-sante-longevite/
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="equilibre-faq-entry">
          <h2 className="question">Quel est le tarif pour lancer le Programme EQUILIBRE ?</h2>
          <div className="answer">
            <ul>
              <li>
                Notre tarif est un <span className="equilibre-bold">abonnement annuel</span> basé sur le nombre de collaborateurs ayant accès au programme.
              </li>
              <li>
                L'abonnement annuel comprend : un accès illimité aux parcours santé guidés et aux
                contenus, un plan de communication détaillé, des supports promotionnels prêts à
                l’emploi, des outils d’animation/d’engagement, un accès au tableau de bord anonymisé
                et réservé au chef de projet et à la Direction, un accompagnement opérationnel par
                les équipes Sharecare.
              </li>
              <li>
                Pour obtenir un devis, contactez nous sur{' '}
                <a href="mailto:contact.europe@sharecare.com" target="_blank" rel="noreferrer">
                  contact.europe@sharecare.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Page.Main>
    </Page>
  );
};

export default ProspectFaq;
